




































import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { SuggestService, FileService } from "src/services";

@Component
export default class UserSetting extends Vue
{
    /**
     * form表单
     * @protected
     */
    protected formData: any =
    {
        content: "",
        imgIds: "",
        contactInfo: ""
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 上传文件
     * @protected
     * @reyurns Array<any>
     */
    protected uploader: Array<any> = []

    /**
     * 上传文件
     * @private
     * @returns Promise<void>
     */
    private async onUploadFile(): Promise<void>
    {
        if (this.uploader.length > 0)
        {
            try
            {
                let formData = new FormData();
                this.uploader.forEach(item=>
                {
                    formData.append("files", item.file);
                })
                formData.append("useType", "1");
                let {content: result} = await FileService.instance.uploadFile(formData);
                if (result.data)
                {
                    let ids = "";
                    result.data.forEach(item => {
                        ids += item.id + ','
                    });
                    this.formData.imgIds = ids.substring(0, ids.length - 1);
                    this.onSubmit();
                }
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
        }
        else
        {
            this.onSubmit();
        }
    }

    /**
     * 提交
     * @private
     * @returns Promise<void>
     */
    private async onSubmit(): Promise<void>
    {
        try
        {
            await SuggestService.instance.addSuggest(this.formData);
            Toast("意见反馈成功!");
            this.$router.go(-1);
        }
        catch(err)
        {
            Toast(JSON.stringify(err))
        }
    }

}
