var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-pwd"},[_c('div',{staticClass:"update-pwd-form"},[_c('div',{staticClass:"update-pwd-img"},[(_vm.orgInfo.logo && _vm.orgInfo.logo.fullPath)?_c('img',{staticClass:"update-pwd-img-warp",attrs:{"src":_vm.orgInfo.logo.fullPath}}):_vm._e()]),_c('van-form',{on:{"submit":_vm.onSave}},[_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"right","rules":[
                    {required: true, message: '请输入手机号',trigger: 'onBlur' },
                    { validator:_vm.validatorPhone, message: '请输入正确手机号',trigger: 'onBlur' } ],"type":"number","clearable":"","maxlength":"11","placeholder":"请输入手机号"},model:{value:(_vm.modelData.phone),callback:function ($$v) {_vm.$set(_vm.modelData, "phone", $$v)},expression:"modelData.phone"}}),_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"right","type":"number","rules":[{required: true, message: '请输入验证码',trigger: 'onBlur' }],"clearable":"","maxlength":"6","placeholder":"请输入验证码"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticClass:"validator-btn",attrs:{"native-type":"button","disabled":_vm.isDisabled,"size":"mini","color":"#02b0a7"},on:{"click":_vm.onGetModifyCode}},[_vm._v(_vm._s(_vm.sendCode))])]},proxy:true}]),model:{value:(_vm.modelData.code),callback:function ($$v) {_vm.$set(_vm.modelData, "code", $$v)},expression:"modelData.code"}}),_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"right","type":"password","rules":[
                {required: true, message: '请输入新的密码',trigger: 'onBlur' },
                {validator:_vm.validatorpwdLength, message: '密码长度在6-20之间',trigger: 'onBlur' }
                ],"placeholder":"请输入新的密码（6-20位数字和字母的组合）"},model:{value:(_vm.modelData.newPwd),callback:function ($$v) {_vm.$set(_vm.modelData, "newPwd", $$v)},expression:"modelData.newPwd"}}),_c('van-field',{staticClass:"update-pwd-form-item",attrs:{"error-message-align":"right","type":"password","rules":[
                    {required: true, message: '请输入确认新密码',trigger: 'onBlur' },
                    { validator:_vm.validatorPwd, message: '两次密码输入不一致',trigger: 'onBlur' }
                ],"placeholder":"请再次输入新密码"},model:{value:(_vm.modelData.confirm),callback:function ($$v) {_vm.$set(_vm.modelData, "confirm", $$v)},expression:"modelData.confirm"}}),_c('van-button',{staticClass:"btn btn-block update-btn",attrs:{"type":"onSave","disabled":_vm.isSubmit}},[_vm._v("确认修改")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }