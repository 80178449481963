
















































import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { FamilyService } from "src/services";
import dayjs from "dayjs";

@Component
export default class UserSetting extends Vue
{
    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }
    
    /**
     * 联系人数据模型
     * @private
     *
     */
    private modelData: any =
    {
        id:null,
        memberType: 2,
        memberName:"", // 用户姓名
        familyType: null, // 用户关系
        familyTypeName: "",// 用户关系名称
        phone: "", // 手机号
        sex: 0, // 用户性别
        birthday: null, // 生日
        age: null, // 年龄
        idTypeName: null,
        identityCardType: null, // 证件类型
        marriageStatus: 1, // 用户婚姻状态
        cardNo: "", // 证件号
        remark: "" // 备注
    };

    /**
     * 跳转修改密码页面
     * @private
     * @returns void
     */
    private onModifyPwd(): void
    {
        this.$router.push({name: "updatePwd"});
    }

    /**
     * 退出登录
     * @private
     * @returns void
     */
    private async onSignOut(): Promise<void>
    {

        Dialog.confirm({
            message: "是否退出登录",
            confirmButtonText: "是",
            cancelButtonColor: "#9A9A9A",
            cancelButtonText: "否",
            confirmButtonColor: "#343434"
        })
        .then(() =>
        {
            window.localStorage.clear();
            Toast.success("退出成功");
            this.$router.push({name: "login"});
        });
    }

    /**
     * 获取用户详情
     * @private
     * @returns Promise<void>
     */
    private async created(): Promise<void>
    {
        this.getUserDetail();
    }

     /**
     * 获取企业信息
     * @private
     * @returns any
     */
    private get orgInfo(): any
    {
        let orgInfoStorge = localStorage.getItem("orgInfo");
        if(orgInfoStorge)
        {
            return JSON.parse(orgInfoStorge)
        }
        return {};
    }

    /**
     * 获取用户详情
     * @private
     * @returns Promise<void>
     */
    private async getUserDetail(): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyDetail(this.userInfo.userId);

            if(result.data)
            {
                this.modelData =
                {
                    id: result.data.id,
                    memberType: result.data.memberType,
                    memberName: result.data.memberName, // 用户姓名
                    familyType: result.data.familyType, // 用户关系
                    phone: result.data.phone, // 手机号
                    sex: result.data.sex, // 用户性别
                    birthday: dayjs(result.data.birthday).format("YYYY/MM/DD"), // 生日
                    age: result.data.age, // 年龄
                    identityCardType: result.data.identityCardType, // 证件类型
                    marriageStatus: result.data.marriageStatus, // 用户婚姻状态
                    cardNo: result.data.cardNo, // 证件号
                    remark: result.data.remark // 备注
                };

            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

}
