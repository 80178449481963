













































































import { Component, Vue } from "vue-property-decorator";
import { UserService } from "src/services";
import { Toast } from "vant";
import patterns from "src/utils/patterns";

@Component
export default class RetrievePwd extends Vue
{
    /**
     * 修改数据模型
     * @private
     * @returns string
     */
    private modelData: any =
    {
        phone: "",
        code: "",
        newPwd: "",
        confirm: ""
    };

    /**
     * 点击获取验证码等60秒完成后才可以重新点击发送
     * @private
     * @returns boolean
     */
    private isDisabled: boolean = false;

    /**
     * 获取验证码
     * @private
     * @returns string
     */
    private sendCode: string = "获取验证码";

    /**
     * 获取验证码60秒
     * @private
     * @returns string
     */
    private time: number = 60;

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get isSubmit(): any
    {
        return !this.modelData.phone || !this.modelData.code || !this.modelData.newPwd || !this.modelData.confirm;
    }

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 验证两次密码是否一直
     * @private
     * 
     * @returns void
     */
    private validatorPwd(val: any): any
    {
        return this.modelData.newPwd === val;
    }

    /**
     * 验证是否正确手机号
     * @private
     * @returns void
     */
    private validatorPhone(val: any): any
    {
        return patterns.mobile.test(val);
    }

    /**
     * 验证密码长度
     * @private
     * @returns void
     */
    private validatorpwdLength(val: any): any
    {
        return val.length >= 6 &&  val.length <= 20;
    }

    /**
     * 获取验证码
     * @private
     * @returns void
     */
    private async onGetModifyCode(): Promise<void>
    {
        try
        {
            if(!this.modelData.phone)
            {
                Toast({
                    duration: 1000,
                    message: "请输入11位手机号"
                });

                return;
            }

            let data =
            {
                smsType: 2, // 找回密码
                phoneNum: this.modelData.phone
            };

            await UserService.instance.smsSend(data);
           
            Toast("验证码发送成功");
            this.isDisabled = true;
            let interval = window.setInterval(() =>
            {
                this.sendCode = `${this.time}s`;
                --this.time;
                if(this.time < 0)
                {
                    this.sendCode = "重新发送";
                    this.time = 60;
                    this.isDisabled = false;
                    window.clearInterval(interval);
                }
            },1000);
        }
        catch(err)
        {
            Toast(err);
        }

    }

    /**
     * 保存
     * @private
     * @returns void
     */
    private async onSave(): Promise<void>
    {
        
        try
        {
            let {content: result} =  await UserService.instance.forgetPassword(this.modelData);

            Toast("修改密码成功");
            this.$router.push({name: "login"});
        }
        catch(err)
        {
            Toast(err);
        }
    }
}
