


















































































import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { locale } from "dayjs";
import tabbar from "src/components/tabbar";
import { UserService } from "src/services";

@Component({
    components:
    {
        tabbar
    }
})
export default class PersonalCenter extends Vue
{
    /**
     * 服务数量
     * @private
     * @returns void
     */
    private serviceNums: number = 0;

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 公司信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 联系客服
     * @private
     * @returns void
     */
    private onContact(): void
    {
        Dialog.confirm({
            message: `是否立即拨打客服电话：${this.userInfo.customerServicePhone}`,
            confirmButtonText: "拨打",
            confirmButtonColor: "#43B8A1",
            cancelButtonText: "取消",
            cancelButtonColor: "#cccccc"
        }).then(() =>
        {
            window.location.href = `tel: ${this.userInfo.customerServicePhone}`;
        });
    }

    /**
     * 跳转订单列表
     * @private
     * @returns void
     */
    private onOrderDetail(type: string): any
    {
        this.$router.push({name: "order-list", query: {type}});
    }

    /**
     * 跳转报告解读列表
     * @private
     * @returns void
     */
    private onPowerReport(): any
    {
        this.$router.push({name: "powerReportOrderList"});
    }

    /**
     * 跳转绿通订单列表
     * @private
     * @returns void
     */
    private onChannelOrderList(type: string): any
    {
        this.$router.push({name: "channel-order-list", query: {type}});
    }

    /**
     * 跳转细胞存储
     * @private
     * @returns void
     */
    private onCellStockpile(type: string): any
    {
        this.$router.push({name: "cellStockpile-order-list", query: {type}});
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string): void
    {
        this.$router.push({name});
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onOpenUrl(url: string): void
    {
        location.href = url;
    }

    /**
     * 跳转投诉建议
     * @private
     * @returns void
     */
    private async onSuggestion(): Promise<void>
    {
        Toast({
            duration: 1000,
            message: "此功能正在开发中,敬请期待!"
        });
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getMyUsingServiceNums();
    }

    /**
     * 获取权益数量
     * @private
     * @returns void
     */
    private async getMyUsingServiceNums(): Promise<void>
    {
        try
        {
            let {content: result} = await UserService.instance.getMyUsingServiceNums();
            this.serviceNums = result.data || 0;
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

}
